import axios from 'axios';

export default {
    setupInterceptors: (store) => {
        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            //catches if the session ended!
            if ( error.response && error.response.status === 401) {
                store.dispatch({ type: 'SIGN_OUT' });
            }
            return Promise.reject(error);
        });

    }
};
