import { handleActions } from 'redux-actions';
import * as authAction from './actions';

const initState = {
    status: "IDLE",
    loading: false,
    errorMessage: null,
    key: null,
    login: null,
    organization: {}
}

const authorization = handleActions(
    {
        [authAction.signIn]: (state, _action) => {
            return { ...state, loading: true };
        },
        [authAction.signInSuccess]: (state, { payload }) => {
            const { key, login, organization } = payload;
            return { ...state, status: "SUCCESS", authError: null, loading: false, login, key, organization };
        },
        [authAction.signInError]: (state, { payload }) => {
            return { ...state, status: "FAIL", loading: false, errorMessage: payload };

        },
        [authAction.signOutSuccess]: (state, _action) => {
            return { ...initState };
        },

        [authAction.updateReportStatus]: (state, _action) => {
            return { ...state, organization: { ...state.organization, report_submitted: true }};
        },
    },
    initState
);

export default authorization;
