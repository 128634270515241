import {createActions} from "redux-actions";

const {
  formikLoadState,
  formikSaveState,
  formikClearState
} = createActions(
    'FORMIK_LOAD_STATE',
    'FORMIK_SAVE_STATE',
    'FORMIK_CLEAR_STATE'
);

export {
  formikLoadState,
  formikSaveState,
  formikClearState
};
