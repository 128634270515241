export const MENU =
// [{
//     id: "passport",
//     icon: "iconsminds-rename",
//     label: "Главное меню",
//     to: "/app",
//     subs:
[
    {
    "id": "intro",
    "icon": "simple-icon-info",
    "label": "Введение",
    "to": "/app/intro",
    "component": "Intro",
    "page_title": null
    },
    {
    "id": "statistic",
    "icon": "simple-icon-list",
    "label": "Основные данные",
    "to": "/app/statistics",
    "component": "Statistic",
    "page_title": null,
    },
    {
    "id": "room",
    "icon": "simple-icon-layers",
    "label": "Помещения",
    "to": "/app/room",
    "component": "Room",
    "page_title": null
    },
    {
    "id": "equipment",
    "icon": "simple-icon-speedometer",
    "label": "Оборудование",
    "to": "/app/equipment",
    "component": "Equipment",
    "page_title": null
    },
    {
    "id": "print_form",
    "icon": "simple-icon-printer",
    "label": "Печатные формы",
    "to": "/app/fetch_form",
    "component": "FetchForm",
    "page_title": null
    },
    {
    "id": "upload_report",
    "icon": "simple-icon-cloud-upload",
    "label": "Отчёт аудитора",
    "to": "/app/upload_report",
    "component": "UploadReport",
    "page_title": null
    },
]


// }]
