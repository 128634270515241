// const fetchFormPrefixFile = 'http://92.38.235.180:16655/report' ;
// const dropZoneUrl = 'http://92.38.235.180:16655/upload_file.php';
// const serverUrl = 'http://92.38.235.180:49882/socpit';
// const serverUrl = 'http://127.0.0.1:7788/socpit';
const serverUrl = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai/socpit';
const loginUrl = serverUrl + '/login';
const fetchFormPrefixFile = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai/report' ;
const formSubmitUrl = serverUrl + '/upload_form';
const fetchFormUrl = serverUrl + '/get_forms';
const fetchFormJson = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai:5700/forms/';
const dropZoneUrl = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai/upload_file.php';
const gogoSaveState = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai/save_state.php';
const gogoGetState = 'https://xn--80aaf4afbmgdb4am5ezc5bh.xn--p1ai/get_state.php';
const gogoCheckSession = serverUrl + '/check_session';
const submitReport = serverUrl + '/submit_report';
const submitFeedback = 'https://92.38.235.180/send_email.php';


const headers = {
	// 'Access-Control-Request-Headers': 'Content-Type, User-Agent, If-Modified-Since, Cache-Control, Range',
	// 'Access-Control-Allow-Origin' : '*',
	// 'Access-Control-Allow-Credentials': 'true',
	// 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
	// 'Access-Control-Allow-Headers': 'Content-Type, User-Agent, If-Modified-Since, Cache-Control, Range'
}


export {
    serverUrl,
    loginUrl,
    formSubmitUrl,
    fetchFormUrl,
    fetchFormPrefixFile,
    dropZoneUrl,
    gogoSaveState,
    gogoCheckSession,
    gogoGetState,
    submitReport,
    submitFeedback,
    headers,
    fetchFormJson
}
