import axios from 'axios';
import {fetchFormUrl, loginUrl, headers} from '../constants/apiUrl';

export const authRequest = payload => {
    return axios.post(loginUrl, JSON.stringify(payload), {headers: headers}).then(response => response)
};

export const fetchFormRequest = token => {
    const config = {
        headers: { 'Token': token, 'Content-Type': 'application/json' }
    };
    return axios.get(fetchFormUrl, config).then(response => response)
};
