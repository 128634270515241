import { handleActions } from 'redux-actions';
import * as dashboardActions from './actions';
import { combineReducers } from 'redux';
import moment from 'moment';
import { dateFormat } from '../../constants/payloads'
import {MENU} from "../../constants/menuFiller";

const formInit = {
    fetch_form_data:[],
    fetch_form_status: "IDLE",
    fetch_form_error_msg: ""
}

const statisticState = {
    formLoading:true,
    loading: false,
    reseted: false,
    errorMessage: null,
    sales_loading: false,
    units: [],
    data: {},
    orders_list: [],
    orders_limit: null,
    sales_list: [],
    status: "IDLE",
    selectedPeriod: [moment().format(dateFormat), moment().format(dateFormat)],
    selectedUnits: undefined,
    updateBtnActive: false,
    menu: MENU,
    kpi_data: [],
    ...formInit
}


const statistic = handleActions(
    {
        [dashboardActions.toggleLoading]: (state, { _payload }) => ({ ...state, formLoading: !state.formLoading }),
        [dashboardActions.getStat]: (state, { _payload }) => ({ ...state, loading: true }),
        // [dashboardActions.getOrders]: (state, { _payload }) => ({ ...state, orders_list: [] }),
        [dashboardActions.getStatByLogin]: (state, { payload }) =>
            ({ ...state, units: payload.units, data: { ...payload }, loading: false, reseted: false, orders_limit: payload.orders_limit }),
        [dashboardActions.getSalesList]: (state, { _payload }) => ({ ...state, sales_loading: true, sales_list: [], updateBtnActive: false }),
        [dashboardActions.getOrdersListSuccess]: (state, { payload }) => ({ ...state, orders_list: [...payload] }),
        [dashboardActions.getSalesListSuccess]: (state, { payload }) => ({ ...state, sales_list: [...payload], sales_loading: false, reseted: true, updateBtnActive: false }),
        [dashboardActions.getStatSuccess]: (state, { payload }) =>
            ({ ...state, data: { ...payload }, orders_list: [], status: "SUCCESS", loading: false, reseted: false, sales_list: [], updateBtnActive: false }),
        [dashboardActions.getStatFail]: (state, { payload }) => ({ ...state, status: "FAIL", errorMessage: payload, loading: false, sales_loading: false }),
        [dashboardActions.setStatisticPeriod]: (state, { payload }) => ({ ...state, selectedPeriod: payload, updateBtnActive: true }),
        [dashboardActions.setSelectedUnits]: (state, { payload }) => ({ ...state, selectedUnits: payload, updateBtnActive: true }),
        [dashboardActions.loadSalesListSuccess]: (state, { payload }) => ({ ...state, sales_list: payload, sales_loading: false }),
        [dashboardActions.loadStatSuccess]: (state, { payload }) => ({ ...state, data: { ...payload }, loading: false, reseted: false, updateBtnActive: false }),
        [dashboardActions.getKpi]: (state, { _payload }) => ({ ...state, kpi_data: [], loading: true }),
        [dashboardActions.getKpiSuccess]: (state, { payload: kpi_data }) => ({ ...state, kpi_data, loading: false, status: "SUCCESS", errorMessage: null }),
        [dashboardActions.getKpiFail]: (state, { payload: errorMessage }) => ({ ...state, loading: false, status: "FAIL", errorMessage }),
        [dashboardActions.toggleStatisticLoading]: (state, _p) => ({ ...state, loading: !state.loading }),
        [dashboardActions.fetchForm]: (state, _p) => ({ ...state, loading: true }),
        [dashboardActions.fetchFormSuccess]: (state, {payload}) => ({ ...state, loading: false, fetch_form_data: [...payload], fetch_form_status: "SUCCESS" }),
        [dashboardActions.fetchFormError]: (state, { payload: errorMessage }) => ({ ...state, loading: false, fetch_form_status: "FAIL", fetch_form_error_msg: errorMessage }),
        [dashboardActions.resetForm]: (state, _p) => ({ ...state, loading: false, ...formInit }),
    }, statisticState

);

const kpiTable = handleActions({
    [dashboardActions.getKpiTable]: (state, { payload: { periods } }) => {
        return { ...state, ...periods, data: [], loading: true };
    },
    [dashboardActions.getKpiTableSuccess]: (state, { payload: data }) => {
        return { ...state, data, loading: false, status: "SUCCESS", errorMessage: null };
    },
    [dashboardActions.getKpiTableFail]: (state, { payload }) => {
        return { ...state, loading: false, status: "FAIL", errorMessage: payload };
    },
    [dashboardActions.setKpiTablePeriod]: (state, { payload }) => {
        return { ...state, ...payload };
    },

}, {
    status: "IDLE",
    loading: false,
    data: [],
    errorMessage: null,
    periodA: [moment().format(dateFormat), moment().format(dateFormat)],
    periodB: [moment().subtract(7, 'days').format(dateFormat), moment().subtract(7, 'days').format(dateFormat)]
})




export default combineReducers({
    statistic,
    kpiTable
});
