import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import dashSagas from './dash/saga';


export default function* rootSaga(_some) {
  yield all([
    authSagas(),
    dashSagas()
  ]);
}
