import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import reducers from './reducers';
import sagas from "./sagas";
import NetworkService from '../network_service';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
    const composeEnhancers = composeWithDevToolsDevelopmentOnly({
      actionsDenylist: ['MENU_SET_CLASSNAMES', 'MENU_CHANGE_HAS_SUB_ITEM_STATUS']
    });

    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    NetworkService.setupInterceptors(store);

    sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
