import axios from 'axios';
import { takeEvery, put, call } from 'redux-saga/effects';
import * as authActions from '../actions';
import { getStatByLogin } from '../dash/actions'
import { AppLocalStorage } from '../../constants/payloads'
import {authRequest} from "../../constants/authRequest";
import {gogoCheckSession, gogoGetState, headers} from '../../constants/apiUrl';

// сброс данных авторизации сессии
const clearSession = JSON.stringify({ JLKSession: {} });

const getData = payload => {
    return axios.post(gogoGetState, JSON.stringify(payload), {headers: headers}).then(response => response)
};

function* load_state(username, client, organization, token) {
  try {
    const { data } = yield call(getData, { username });
    const forms = Object.keys(data);
    if (forms.length) {
      yield put(authActions.formikLoadState({data}));
    }
  } catch (err) {
    console.error(err);
  }
}



// авторизация в системе, заполнение localStorage данными от сервера
function* signSaga({ token, client = {}, organization = {}, username, is_load_state = true }) {
  if (!client?.name) {
    client = {name: username}
  }

  if (!organization?.name) {
    organization = {name: username}
  }

  yield put(
    authActions.signInSuccess({ key: token, login: client?.name??username, organization })
  );

  if (!username) {
    const old_sess = JSON.parse(localStorage.getItem(AppLocalStorage));
    username = old_sess?.JLKSession?.username || null;
  }

  localStorage.setItem(AppLocalStorage, JSON.stringify({
    JLKSession: { token, client, organization, username },
  }));

  if (username && is_load_state) {
    yield load_state(username, client, organization, token);
  }

  yield put(getStatByLogin({client, organization, token }));
}

// ошибка авторизации: сообщение в консоль, сброс данных авторизации
function* errorSaga(error, msg) {
  console.error('authSaga Saga Error response', msg);
  localStorage.setItem(AppLocalStorage, clearSession);
  yield put(authActions.signInError(error));
}

const authCheck = payload => {
    return axios.post(gogoCheckSession, JSON.stringify(payload), {headers: headers}).then(response => response)
};

function* isSessionValid() {
    const old_sess = JSON.parse(localStorage.getItem(AppLocalStorage));

    try {
      const resp = yield call(authCheck, { token: old_sess?.JLKSession?.token });
      if (!Object.keys(resp).length) {
        console.error("Ошибка проверки сессии");
        return
      }
      if (!resp?.data?.token) {
        yield signOutSaga();
      }
      yield signSaga({
        ...resp?.data,
        username: old_sess?.JLKSession?.username,
        is_load_state: false
      });
    } catch (error) {
      yield signOutSaga();
    }
}


function* signOutSaga() {
  try {
    localStorage.setItem(AppLocalStorage, clearSession);
    yield put(authActions.signOutSuccess());
  } catch (error) {
    console.error('signOutSaga Saga Error response', error);
  }
}

function* signInSaga({ payload }) {
  try {
    const response = yield call(authRequest, payload);
    if (response.status === 200) {
      yield signSaga({ ...response.data, username: payload?.username });
    } else {
      throw response;
    }
  } catch (error) {
    yield errorSaga(error.response?error.response.data.err:'', error.response);
  }
}

function* restoreInSaga({ payload }) {
  try {
    if (payload.token) {
      yield signSaga(payload);
    } else {
      throw new Error('token is not contain in payload');
    }
  } catch (error) {
    yield errorSaga(error, error);
  }
}


export default function* authWatcher() {
    yield takeEvery(authActions.signIn.toString(), signInSaga);
    yield takeEvery(authActions.signOut.toString(), signOutSaga);
    yield takeEvery(authActions.restoreIn.toString(), restoreInSaga);
    yield takeEvery(authActions.checkSession.toString(), isSessionValid);
}
