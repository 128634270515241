import axios from 'axios';
import { handleActions } from 'redux-actions';
import * as action from './actions';
import { AppLocalStorage } from '../../constants/payloads'
import {gogoSaveState, headers} from '../../constants/apiUrl';

var initState = {},
    // username = JSON.parse(localStorage.getItem(AppLocalStorage))?.JLKSession?.username || null,
    send_timeout;

function get_timeout(send_delay, send_data) {
      send_timeout = setTimeout(async function () {
        const resp = await axios.post(gogoSaveState, JSON.stringify(send_data), {headers: headers})
         .catch(err => console.error(gogoSaveState, err) || ({}))
         .then(response => response);

        if (!Object.keys(resp).length) {
          console.error("Ошибка сохранения состояния");
        }
        return
      }, send_delay);
}

// константа, объект с полем sub_fields
const SUB_FIELDS = { sub_fields: {} };

const formik = handleActions(
    {
        [action.formikClearState]: (_state, _action) => (initState),
        [action.formikLoadState]: (_state, { payload }) => {
          const data = payload?.data || {};
          const keys = Object.keys(data);

					// проверка наличия полей sub_fields у данных, содежащих "150" в начале ключа
					const result = {};
          for (const k of keys) {
  					const obj = {};
            for (const [key, value] of Object.entries(data[k])) {

              // "150" в начале ключа, "-" среди цифр, "+" в конце
  						const is150 = key.match(/^150[0-9\-]+\+$/g);

							switch (true) {
  							case is150?.length && ['array', 'object'].includes(typeof value) && JSON.stringify(value) === '[]':
									// подмена пустого списка на объект с полем sub_fields
  								obj[key] = SUB_FIELDS
  								break;

  							case is150?.length && typeof value === 'object' && !('sub_fields' in value):
									// добавление поля sub_fields
  								obj[key] = Object.assign(value, SUB_FIELDS)
  								break;

  							default:
									// вернуть поле без изменений
    							obj[key] = value;
  								break;
							}
            }
            result[k] = obj;
          }

          return result;
        },

        [action.formikSaveState]: (state, { payload }) => {
            if (!payload) return;

            state[payload.form] = payload.value;

            let username = JSON.parse(localStorage.getItem(AppLocalStorage))?.JLKSession?.username || null

            if (username) {
              clearTimeout(send_timeout); // здесь send_timeout всегда неопределён [TODO]
              send_timeout = get_timeout(1000, { username, data: state });
            }
            return state;
        },
    },
    initState
);

export default formik;
