import { createActions } from "redux-actions";

const {
    getStat,
    getOrders,
    getSalesList,
    getStatSuccess,
    getStatFail,
    getKpiTable,
    getKpiTableSuccess,
    getKpiTableFail,
    setKpiTablePeriod,
    setStatisticPeriod,
    getSalesListSuccess,
    getOrdersList,
    getOrdersListSuccess,
    setSelectedUnits,
    loadSalesList,
    loadSalesListSuccess,
    loadStat,
    loadStatSuccess,
    getStatByLogin,
    getKpi,
    getKpiSuccess,
    getKpiFail,
    toggleLoading,
    toggleStatisticLoading,
    fetchForm,
    fetchFormSuccess,
    fetchFormError,
    resetForm
} = createActions(
    'GET_STAT',
    'GET_ORDERS',
    'GET_SALES_LIST',
    'GET_STAT_SUCCESS',
    'GET_STAT_FAIL',
    'GET_KPI_TABLE',
    'GET_KPI_TABLE_SUCCESS',
    'GET_KPI_TABLE_FAIL',
    'SET_KPI_TABLE_PERIOD',
    'SET_STATISTIC_PERIOD',
    "GET_SALES_LIST_SUCCESS",
    'GET_ORDERS_LIST',
    "GET_ORDERS_LIST_SUCCESS",
    "SET_SELECTED_UNITS",
    "LOAD_SALES_LIST",
    "LOAD_SALES_LIST_SUCCESS",
    "LOAD_STAT",
    "LOAD_STAT_SUCCESS",
    "GET_STAT_BY_LOGIN",
    "GET_KPI",
    "GET_KPI_SUCCESS",
    "GET_KPI_FAIL",
    "TOGGLE_LOADING",
    "TOGGLE_STATISTIC_LOADING",
    "FETCH_FORM",
    "FETCH_FORM_SUCCESS",
    "FETCH_FORM_ERROR",
    "RESET_FORM",
);

export {
    getStat,
    getOrders,
    getSalesList,
    getStatSuccess,
    getStatFail,
    getKpiTable,
    getKpiTableSuccess,
    getKpiTableFail,
    setKpiTablePeriod,
    setStatisticPeriod,
    getSalesListSuccess,
    getOrdersList,
    getOrdersListSuccess,
    setSelectedUnits,
    loadSalesList,
    loadSalesListSuccess,
    loadStat,
    loadStatSuccess,
    getStatByLogin,
    getKpi,
    getKpiSuccess,
    getKpiFail,
    toggleLoading,
    toggleStatisticLoading,
    fetchForm,
    fetchFormSuccess,
    fetchFormError,
    resetForm
};
