import {createActions} from "redux-actions";

const {
  signIn,
  restoreIn,
  signInSuccess,
  signInError,
  signOut,
	signOutSuccess,
  signUp,
  checkSession,
  updateReportStatus
} = createActions(
    'SIGN_IN',
    'RESTORE_IN',
    'SIGN_IN_SUCCESS',
    'SIGN_IN_ERROR',
    'SIGN_OUT',
    'SIGN_OUT_SUCCESS',
    'SIGN_UP',
    'CHECK_SESSION',
    'UPDATE_REPORT_STATUS'
);

export {
  signIn,
  restoreIn,
  signInSuccess,
  signInError,
  signOut,
  signUp,
  signOutSuccess,
  checkSession,
  updateReportStatus
};
