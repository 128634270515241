const AppLocalStorage = 'https://jupiter.report/';

const dateFormat = 'YYYY-MM-DD';

const OrderCnst = 'sales-order'

const ui_version = 3.1;

const RestorePayload = {
    data_tt: "",
    from_data_date: null,
    key: null,
    login: null,
    to_data_date: null,
    type: "restore",
    ui_version
}

const storagePayload = {
    JLKSession: RestorePayload
}

const orderListPayload = {
    key: null,
    from_data_date: null,
    to_data_date: null,
    login: null,
    type: "get_order_list",
    data_tt: "",
    ui_version
}


const dataKpiPayload = {
    from_date_p1: null,
    from_date_p2: null,
    key: null,
    login: null,
    to_date_p1: null,
    to_date_p2: null,
    type: "get_kpi_table_v2",
    ui_version: 2.63
}

const salesListPayload = {
    data_tt: "",
    from_data_date: null,
    key: null,
    login: null,
    to_data_date: null,
    type: "get_sales_list",
    ui_version
}

const DataRangePayload = {
    data_tt: "",
    from_data_date: null,
    key: null,
    login: null,
    to_data_date: null,
    type: "get_data_range",
    ui_version
}

const KPIPayload = {
    "key": null,
    "type": "get_kpi_v3",
    "variant": null,
    ui_version
}

const restorePassword = {
    email: null,
    type: "restore_password",
    ui_version
}

const changePassword = {
    // current_password: "112233",
    key: null,
    login: null,
    new_password: null,
    type: "change_password",
    ui_version
}

export {
    AppLocalStorage,
    dateFormat,
    RestorePayload,
    storagePayload,
    dataKpiPayload,
    OrderCnst,
    DataRangePayload,
    orderListPayload,
    salesListPayload,
    KPIPayload,
    restorePassword,
    changePassword
}